import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import DataTable from "../../component/dataTable/index";
import ContentDialog from "../EditBusinessUnit/dialog";
import CreateUsers from "./createUsers";
import { PlusCircleFill , LeftArrowIcon} from "../../assets/icons/Icons";
import {
  fetchRegionSpecificUser,
  toastTriggered,
  deleteUser
} from "../../redux/actions/index";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from '@material-ui/icons/Edit';
import toastProperties from "../../assets/data/toastProperty";
import utils from '../../utils/index';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 0,
  },
  paper: {
    padding: theme.spacing(0.3, 0),
    width: "100%",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    boxShadow: "none",
    background: "#f4f5f6",
  },
  flexContainer: {
    height: "100%",
    width: "100%",
  },
}));

const CommandCenterUsers = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let history = useHistory();

  const configureUser = useSelector((state) => state.regionSpecificUser);
  const { /*activeRoutes, */ activeRegion } = useSelector((state) => state.activeDynamicData);

  const { users, loading } = configureUser;
  const [targetUsers, setTargetUsers] = useState([]);
  const [user, setSelectedUser] = useState({});
  const [openDialog, setopenDialog] = useState(false);
  const [renderAdGroup, setRenderAdGroup] = useState({});
  

  useEffect(() => {
    if(activeRegion !== ''){
      let params = { region: activeRegion }
      dispatch(fetchRegionSpecificUser(params));
    }
  }, [activeRegion, dispatch]);

  useEffect(() => {
    if (!!users && Object.keys(users).length > 0) {
      setTargetUsers(users.user);
      Array.isArray(users.user) &&
        users.user.length > 0 &&
        users.user.forEach((item, index) => {
          item.id = index + 1;
          return users;
        });
    }
    else if (!!users && Object.keys(users).length === 0) {
      setTargetUsers([]);
    }
  }, [configureUser, users]);

  const trackUserActivities = (item) => {
    let trackingObject = {
      region: item.Region,
      actionType: 'Click',
      pageName: "Other Feature",
      reportName: "User",
      reportSection: 'User Deleted',
      pageDetails: `${item.alertName}`,
    };
    utils.userTrackingInfo(trackingObject);
  };

  const deleteSelUser = (id) => {
    let  item = targetUsers.find((el) => el['Email'] === id);
    let  params = {
        userId: id
      };
    if(item){
      let text = `Are You Sure You Want to Delete the user ${item['First Name']} ${item['Last Name']} Parmanently? This Action Can't be UNDONE`;
      if (window.confirm(text)) {
        dispatch(deleteUser(params)).then((res) => {
          toastProperties.success["description"] = res.data.message;
          dispatch(toastTriggered([toastProperties.success]));
          let params = { region: activeRegion }
          dispatch(fetchRegionSpecificUser(params));
          trackUserActivities(item)
        })
          .catch((err) => {
            toastProperties.error["description"] = `There is some problem while deleting the user details in region ${activeRegion}. Please try again in some time.`;
            dispatch(toastTriggered([toastProperties.error]));
          });
      }
    }
    else{
      toastProperties.error["description"] = `There is some problem while deleting the user details in region ${activeRegion}. Please try again in some time.`;
      dispatch(toastTriggered([toastProperties.error]));
    }
  };

  const closeDialog = () => {
    setopenDialog(false);
  };

  const renderModuleContent = () => {
    if(renderAdGroup.viewAdGroup){
      return (
        <ul>
          {
            Array.isArray(renderAdGroup.groups) && renderAdGroup.groups.length>0 &&
            renderAdGroup.groups.map((el)=>
              <li>{el}</li>  
            )
          }
        </ul>
      )
    }
    else{
      return (
        <CreateUsers
          userDetails={user}
          updateUser={renderAdGroup['updateUser']}
          closeModal={closeDialog}
        />
      )
    }
  }

  const renderModuleTitle = () =>{
    if(renderAdGroup['viewAdGroup']){
      return(
        <span>Assigned Ad Groups</span>
      )
    }
    else if(renderAdGroup['updateUser']){
      return(
        <span>Update User Profile</span>
      )
    }
    else if(!renderAdGroup['updateUser']){
      return(
        <span>Create User Profile</span>
      )
    }
    
  };

  const renderContentDialog = () => {
    return (
      <ContentDialog
        contentTitle={
          renderModuleTitle()
        }
        openDialog={openDialog}
        closeDialog={closeDialog}
        moduleContent={
          renderModuleContent()
        }
      />
    )
  };

  const updateUserDetails = (dataIndex, rowData) => {
    let rowIndex = rowData.rowIndex;
    let selectedUser = targetUsers[rowIndex];
    setSelectedUser(selectedUser)
    setopenDialog(true);
    renderAdGroup.updateUser = true;
    setRenderAdGroup(renderAdGroup);
  };

  const renderIsAdmin = (dataIndex, rowData) => {
    let rowIndex = rowData.rowIndex;
    let selectedUser = targetUsers[rowIndex];
    if (selectedUser && selectedUser.hasOwnProperty('AdGroups')) {
      let AdGroups = JSON.parse(selectedUser.AdGroups);
      if (Array.isArray(AdGroups) && AdGroups.length > 0) {
        let regionContributor = AdGroups[0].split('_');
        let isAdmin = regionContributor[6] === "Contributor" ? 'Yes' : "No";
        return (
          <span>{isAdmin}</span>
        )
      }
      else{
        return (
          <span>{'-'}</span>
        )
      }
    }
  };

  const viewAdGroup = (AdGroups) =>{
    setopenDialog(true); 
    renderAdGroup.updateUser = false;
    renderAdGroup.viewAdGroup = true;
    renderAdGroup.groups = AdGroups;
    setRenderAdGroup(renderAdGroup)
  }

  const renderAdGroups = (dataIndex, rowData) => {
    let rowIndex = rowData.rowIndex;
    let selectedUser = targetUsers[rowIndex];
    if (selectedUser && selectedUser.hasOwnProperty('AdGroups')) {
      let AdGroups = JSON.parse(selectedUser.AdGroups);
      return (
        <i 
        class="fa fa-eye" 
        aria-hidden="true" 
        style={{ fontSize: '15px' }} 
        onClick={ ()=> viewAdGroup(AdGroups)} 
        >
        </i>
      )
    }
  };

  const userLastLogin = (dataIndex, rowData) =>{
    let rowIndex = rowData.rowIndex;
    let selectedUser = targetUsers[rowIndex];
    if (selectedUser && selectedUser.hasOwnProperty('lastLogin')) {
      let userLogin = selectedUser.lastLogin;
      if (userLogin) {
        let currentdate = new Date(userLogin);
        var datetime =  currentdate.getDate() + "/"
        + (currentdate.getMonth()+1)  + "/" 
        + currentdate.getFullYear() + " @ "  
        + currentdate.getHours() + ":"  
        + currentdate.getMinutes() + ":" 
        + currentdate.getSeconds();
         console.log('userLastLogin', userLogin, datetime);
        //  date, day, month, year, hour, minute, loginDate, loginTime
        return (
          <span> {datetime}</span>
        )
      }
      else{
        return (
          <span>{'-'}</span>
        )
      }
    }
  }

  const columns = [
    {
      name: "id",
      label: "S.No.",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "Region",
      label: "Region",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "First Name",
      label: "First Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "Last Name",
      label: "Last Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "Email",
      label: "User Email",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: " KC ID",
      label: "KC ID",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "Key User ",
      label: "Key User",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "User Base Country",
      label: "User Base Country",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "Market",
      label: "Market",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "Team",
      label: "Team",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "AdGroups",
      label: "Ad Group Access",
      options: {
        customBodyRender: (dataIndex, rowData) => {
          return (
            renderAdGroups(dataIndex, rowData)
          );
        },
      },
    },
    {
      name: "Key User ",
      label: "IsAdmin",
      options: {
        customBodyRender: (dataIndex, rowData) => {
          return (
            renderIsAdmin(dataIndex, rowData)
          );
        },
      },
    },
    {
      name: "lastModifiedAt",
      label: "Last Login",
      options: {
        customBodyRender: (dataIndex, rowData) => {
          return (
            userLastLogin(dataIndex, rowData)
          );
        },
      },
    },
    {
      name: "Email",
      label: "Update",
      options: {
        customBodyRender: (dataIndex, rowData) => {
          return (
            <EditIcon
              className={"cursor"}
              onClick={() => updateUserDetails(dataIndex, rowData)}
            />
          );
        },
      },
    },
    {
      name: "Email",
      label: "Delete",
      options: {
        customBodyRender: (dataIndex, rowData) => {
          return (
            <DeleteIcon
              className={"cursor"}
              onClick={() => deleteSelUser(dataIndex, rowData)}
            />
          );
        },
      },
    },
  ];


  const title = React.createElement(
    "div",
    { className: "heading" },
    `${activeRegion} Region Users`
  );

  const newUser = React.createElement(
    "div",
    {
      className: "",
      onClick: () => {
        setopenDialog(true);
        renderAdGroup.updateUser = false;
        setRenderAdGroup(renderAdGroup);
        utils.userTrackingInfo({
          region: activeRegion,
          actionType: 'Click',
          pageName: 'Other Feature',
          reportName: "Users",
          pageDetails: `Redirected from Users to Create new User`,
        });
      },
    },
    "Create New Users"
  );

  const Setting = React.createElement(
    "div",
    {
      className: "alertSetting",
      onClick: () => {
        history.push('/user-profile');
        utils.userTrackingInfo({
          region : activeRegion,
          actionType: 'Click',
          pageName: 'Other Feature',
          reportName: "Users",
          pageDetails: `Redirected from Users to update users details`,
        });
      },
    },
    "Back"
  );

  const createNewUser = React.createElement("div", { className: "displayRowFlex  ml-2   table-btn btn-apply" }, [
    <PlusCircleFill />,
    newUser,
  ]);

  const backIcon = React.createElement("div", { className: "displayRowFlex  mr-2  table-btn btn-apply" }, [
    <LeftArrowIcon />,
    Setting,
  ]);

  const config = React.createElement("div", { className: "displayRowFlex" }, [ 
    backIcon,
    createNewUser
   ]);

  const container = React.createElement("div", { className: "" }, [
    title,
    config
  ]);


  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container item xs={12} className={classes.flexContainer}>
          {renderContentDialog()}
          {/* <Grid ontainer item xs={12} className={classes.flexContainer}>
             {container}
          </Grid> */}
          <DataTable
            data={targetUsers}
            loading={loading}
            title={container}
            columns={columns}
            height={"465px"}
            maxheight={true}
            margin={"1rem 3rem"}
            selectableRows={false}
          />
        </Grid>
      </Paper>
    </div>
  );
};

export default CommandCenterUsers;
